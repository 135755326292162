import {Form as AntForm} from 'antd';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import Form from '../../../../components/Form/Form';
import * as formInputNames from '../../../../consts/form/formInputNames';
import {SERVICES_CONFIGURE_SERVICE} from '../../../../consts/modals/modalTypes';
import authDataStoreService from '../../../../services/auth/authDataStoreService';
import buildingsService from '../../../../services/buildings/buildingsService';
import organizationsService from '../../../../services/organizations/organizationsService';
import currentUserService from '../../../../services/user/currentUserService';
import usersService from '../../../../services/users/usersService';
import {setCurrentUserInfo} from '../../../../state/ducks/currentUser';
import {hideModalAction} from '../../../../state/ducks/modal';
import {setUserInfo} from '../../../../state/ducks/users';
import {makeSelectCurrentUserInfo} from '../../../../state/selectors/currentUser';
import {dispatch} from '../../../../state/store';
import serviceSettingsModalService from './serviceSettingsModalService';

// this component is not used for actions. Demo only
const ServicesAddBuildingModal = () => {
    const currentUserInfo = useSelector(makeSelectCurrentUserInfo());
    const {userResponsibilities} = currentUserInfo || {};

    const [responsibility] = userResponsibilities || [];
    const [form] = AntForm.useForm();
    const {initialValues, formFields} = serviceSettingsModalService.getFormContent(form, responsibility);

    const handleSelect = async (value, name) => {
        const currentFieldValue = form.getFieldValue(name);

        if (currentFieldValue === value) return;

        form.setFieldsValue({[name]: value});

        switch (name) {
            case formInputNames.COMPANY:
                await buildingsService.getBuildingsListByOrganizationId(value);
                break;
            default:
                break;
        }
    };

    const fetchModalData = async () => {
        const token = authDataStoreService.parseJWTFromStorage();

        const {id} = token || {};

        dispatch(setUserInfo({id}));
        await usersService.getUserInfoById({id});

        dispatch(setCurrentUserInfo({id}));
        await currentUserService.getCurrentUserInfoById({id});

        await organizationsService.getOrganizationsNames(responsibility?.cityId);
    };

    useEffect(() => {
        fetchModalData();

        if (initialValues) {
            form.setFieldsValue(initialValues);
        }
    }, []);
    const handleSubmit = async () => {
        const values = await form.validateFields();
    };

    const handleChange = () => {};

    return (
        <Form
            onFinish={handleSubmit}
            handleSelect={handleSelect}
            cancelButton={'Відмінити'}
            submitButton={'Змінити'}
            closeModal={() => dispatch(hideModalAction(SERVICES_CONFIGURE_SERVICE))}
            formInstance={form}
            title={'Налаштування'}
            formFields={formFields}
            initialValues={initialValues}
            handleChange={handleChange}
        />
    );
};

export default React.memo(ServicesAddBuildingModal);

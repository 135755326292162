import * as apiMethods from '../../consts/api/apiMethods';
import {setLvkData, setLvkSettingsData, setLvkStatisticsData, setLvkStatus} from '../../state/ducks/services';
import ApiClient from '../api/clients/apiClient';
import ModulesHostApiClient from '../api/clients/modulesHostApiClient';
import log from '../logger/log';

const getLvkBuildingLists = async () => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_LVK_BUILDING_LISTS,
            action: setLvkData,
        });
    } catch (e) {
        log.error(`getBuildingsList: ${e}`);
    }
};

const addBuildingToApplied = async ({data, onRequestSuccess}) => {
    await new ApiClient().callPost({
        methodName: apiMethods.SERVICES_LVK_BUILDING_LISTS,
        // mapper: buildingToAppliedMapping,
        requestConfig: {
            data,
        },
        onResponse: onRequestSuccess,
    });
};

const getLvkStatus = async () => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_LVK_STATE,
            action: setLvkStatus,
        });
    } catch (e) {
        log.error(`getLvkStatus: ${e}`);
    }
};

const turnOffLvkDataSending = async ({onRequestSuccess}) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.SERVICES_LVK_OFF,
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`turnOffLvkDataSending: ${e}`);
    }
};

const turnOnLvkDataSending = async ({onRequestSuccess}) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.SERVICES_LVK_ON,
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`turnOnLvkDataSending: ${e}`);
    }
};

const getLvkStatistics = async () => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_LVK_STATISTICS,
            action: setLvkStatisticsData,
        });
    } catch (e) {
        log.error(`getLvkStatistics: ${e}`);
    }
};

const getLvkSettings = async () => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_LVK_SETTINGS,
            action: setLvkSettingsData,
        });
    } catch (e) {
        log.error(`getLvkSettings: ${e}`);
    }
};

const updateLvkSettings = async ({data, onRequestSuccess}) => {
    await new ApiClient().callPost({
        methodName: apiMethods.SERVICES_LVK_SETTINGS,
        requestConfig: {
            data,
        },
        onResponse: onRequestSuccess,
    });

    await new ModulesHostApiClient().callPost({
        methodName: 'schedulers/lvk',
    });
};

export default {
    getLvkBuildingLists,
    addBuildingToApplied,
    getLvkStatus,
    turnOffLvkDataSending,
    turnOnLvkDataSending,
    getLvkSettings,
    updateLvkSettings,
    getLvkStatistics,
};

import {Form as AntForm} from 'antd';
import React, {useEffect} from 'react';

import Form from '../../../../../components/Form/Form';
import * as formInputNames from '../../../../../consts/form/formInputNames';
import * as modalTypes from '../../../../../consts/modals/modalTypes';
import log from '../../../../../services/logger/log';
import serviceArsService from '../../../../../services/services/serviceArsService';
import helpers from '../../../../../utils/helpers';
import arsOrganizationModalService from './arsOrganizationModalService';

const ArsOrganizationModal = (props) => {
    const {onOk, onCancel, modalType} = props;
    const [form] = AntForm.useForm();

    let modalData = {};

    switch (modalType) {
        case modalTypes.ADD_ARS_ORGANIZATION_MODAL_TYPE: {
            modalData = arsOrganizationModalService.getAddArsOrganizationModalContent();
            break;
        }
        case modalTypes.EDIT_ARS_ORGANIZATION_MODAL_TYPE: {
            modalData = arsOrganizationModalService.getEditArsOrganizationModalContent();
            break;
        }

        default:
            break;
    }

    const {formContent, title, submitButton, cancelButton, initialValues, requireDeps} = modalData || {};

    const onFinish = async () => {
        try {
            const values = await form.validateFields();

            const formValues = values;

            helpers.runFunction(onOk, formValues);
        } catch (error) {
            log.error(`arsOrganizationModal - validate failed: ${error}`);
        }
    };

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }

        return () => {
            form.resetFields();
        };
    }, []);

    const resetFormField = (fieldNames) => {
        const fieldsToReset = {};

        fieldNames.forEach((name) => (fieldsToReset[name] = null));
        form.setFieldsValue(fieldsToReset);
    };

    const setFormField = (name, key, value) => form.setFieldsValue({[name]: {id: key, value}});

    const handleRadioChange = (value, name) => {
        form.setFieldsValue({[name]: value});

        switch (name) {
            case formInputNames.SOURCE: {
                resetFormField([formInputNames.FOLDER, formInputNames.LOGIN, formInputNames.PASSWORD]);

                break;
            }
        }
    };

    const handleSelect = async (value, name, option) => {
        const currentValue = form.getFieldValue(name);

        if (currentValue?.value === option.value) return;

        setFormField(name, option.key, option.value);

        switch (name) {
            case formInputNames.CITY: {
                resetFormField([formInputNames.ORGANIZATION, formInputNames.ALL_BUILDINGS]);

                await serviceArsService.getArsUnappliedOrganizationsNames(value);
                break;
            }
            case formInputNames.ORGANIZATION: {
                resetFormField([formInputNames.ALL_BUILDINGS]);

                break;
            }
            case formInputNames.REPORT_TYPE: {
                resetFormField([formInputNames.DEVICE_GROUP]);

                break;
            }

            default:
                break;
        }
    };

    const handleChange = () => {};

    return (
        <Form
            handleRadioChange={handleRadioChange}
            handleSelect={handleSelect}
            closeModal={onCancel}
            initialValues={initialValues}
            submitButton={submitButton}
            onFinish={onFinish}
            title={title}
            formFields={formContent}
            cancelButton={cancelButton}
            formInstance={form}
            requireDeps={requireDeps}
            handleChange={handleChange}
        />
    );
};

export default React.memo(ArsOrganizationModal);

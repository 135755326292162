import * as apiMethods from '../../consts/api/apiMethods';
import {setKvkData, setKvkSettingsData, setKvkStatisticsData, setKvkStatus} from '../../state/ducks/services';
import ApiClient from '../api/clients/apiClient';
import ModulesHostApiClient from '../api/clients/modulesHostApiClient';
import log from '../logger/log';

const showNotification = (message) => {
    let notification = document.getElementById('notification-center');

    if (!notification) {
        notification = document.createElement('div');
        notification.id = 'notification-center';
        document.body.appendChild(notification);
    }

    notification.style.position = 'fixed';
    notification.style.top = '20px';
    notification.style.left = '50%';
    notification.style.transform = 'translateX(-50%)';
    notification.style.backgroundColor = '#f44336';
    notification.style.color = '#fff';
    notification.style.padding = '15px 30px';
    notification.style.borderRadius = '8px';
    notification.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
    notification.style.fontSize = '16px';
    notification.style.fontWeight = 'bold';
    notification.style.textAlign = 'center';
    notification.style.zIndex = '10000';

    notification.textContent = message;

    setTimeout(() => {
        notification.remove();
    }, 3000);
};

const showSuccessNotification = (message) => {
    let notification = document.getElementById('notification-center');

    if (!notification) {
        notification = document.createElement('div');
        notification.id = 'notification-center';
        document.body.appendChild(notification);
    }

    notification.style.position = 'fixed';
    notification.style.top = '20px';
    notification.style.left = '50%';
    notification.style.transform = 'translateX(-50%)';
    notification.style.backgroundColor = '#4caf50';
    notification.style.color = '#fff';
    notification.style.padding = '15px 30px';
    notification.style.borderRadius = '8px';
    notification.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
    notification.style.fontSize = '16px';
    notification.style.fontWeight = 'bold';
    notification.style.textAlign = 'center';
    notification.style.zIndex = '10000';

    notification.textContent = message;

    setTimeout(() => {
        notification.remove();
    }, 3000); // Сповіщення зникне через 3 секунди
};

const showLoadingNotification = (message) => {
    let notification = document.getElementById('notification-center');

    if (!notification) {
        notification = document.createElement('div');
        notification.id = 'notification-center';
        document.body.appendChild(notification);
    }

    notification.style.position = 'fixed';
    notification.style.top = '20px';
    notification.style.left = '50%';
    notification.style.transform = 'translateX(-50%)';
    notification.style.backgroundColor = 'grey';
    notification.style.color = '#fff';
    notification.style.padding = '15px 30px';
    notification.style.borderRadius = '8px';
    notification.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
    notification.style.fontSize = '16px';
    notification.style.fontWeight = 'bold';
    notification.style.textAlign = 'center';
    notification.style.zIndex = '10000';

    notification.textContent = message;

    setTimeout(() => {
        notification.remove();
    }, 3000); // Сповіщення зникне через 3 секунди
};

const getKvkBuildingLists = async () => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_KVK_BUILDING_LISTS,
            action: setKvkData,
        });
    } catch (e) {
        log.error(`getBuildingsList: ${e}`);
    }
};

const addBuildingToApplied = async ({data, onRequestSuccess}) => {
    await new ApiClient().callPost({
        methodName: apiMethods.SERVICES_KVK_BUILDING_LISTS,
        // mapper: buildingToAppliedMapping,
        requestConfig: {
            data,
        },
        onResponse: onRequestSuccess,
    });
};

const getKvkStatus = async () => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_KVK_STATE,
            action: setKvkStatus,
        });
    } catch (e) {
        log.error(`getKvkStatus: ${e}`);
    }
};

const turnOffKvkDataSending = async ({onRequestSuccess}) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.SERVICES_KVK_OFF,
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`turnOffKvkDataSending: ${e}`);
    }
};

const turnOnKvkDataSending = async ({onRequestSuccess}) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.SERVICES_KVK_ON,
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`turnOnKvkDataSending: ${e}`);
    }
};

const getKvkStatistics = async () => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_KVK_STATISTICS,
            action: setKvkStatisticsData,
        });
    } catch (e) {
        log.error(`getKvkStatistics: ${e}`);
    }
};

const getKvkSettings = async () => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_KVK_SETTINGS,
            action: setKvkSettingsData,
        });
    } catch (e) {
        log.error(`getKvkSettings: ${e}`);
    }
};

const updateKvkSettings = async ({data, onRequestSuccess}) => {
    await new ApiClient().callPost({
        methodName: apiMethods.SERVICES_KVK_SETTINGS,
        requestConfig: {
            data,
        },
        onResponse: onRequestSuccess,
    });

    await new ModulesHostApiClient().callPost({
        methodName: 'schedulers/kvk',
    });
};

const executeKvk = async () => {
    try {
        const state = await new ModulesHostApiClient().callGet({
            methodName: 'schedulers/kvk',
        });

        if (state.status === 1) {
            await new ModulesHostApiClient().callPost({
                methodName: 'schedulers/kvk/execute',
            });

            showSuccessNotification('Дані успішно відправлено!');
        } else if (state.status === 2) {
            showLoadingNotification('Дані вже було відправленно!');
        } else {
            showNotification('Не можна відправити дані!');
        }
    } catch (e) {
        console.error(`ExecuteKvk: ${e}`);
    }
};

export default {
    getKvkBuildingLists,
    addBuildingToApplied,
    getKvkStatus,
    turnOffKvkDataSending,
    turnOnKvkDataSending,
    getKvkSettings,
    updateKvkSettings,
    getKvkStatistics,
    executeKvk,
};

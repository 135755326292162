import {Form as AntForm} from 'antd';
import React from 'react';
import {useSelector} from 'react-redux';

import Form from '../../../../components/Form/Form';
import * as formInputNames from '../../../../consts/form/formInputNames';
import {SERVICES_BUILDING_ADD} from '../../../../consts/modals/modalTypes';
import buildingsService from '../../../../services/buildings/buildingsService';
import {hideModalAction} from '../../../../state/ducks/modal';
import {makeSelectCurrentUserInfo} from '../../../../state/selectors/currentUser';
import {dispatch} from '../../../../state/store';
import servicesModalService from './servicesModalService';

function ServicesAddBuildingModal() {
    const currentUserInfo = useSelector(makeSelectCurrentUserInfo());
    const {userResponsibilities} = currentUserInfo || {};

    const [responsibility] = userResponsibilities || [];
    const [form] = AntForm.useForm();
    const {initialValues, formFields} = servicesModalService.getFormContent(form, responsibility);

    const handleSelect = async (value, name) => {
        const currentFieldValue = form.getFieldValue(name);

        if (currentFieldValue === value) return;

        form.setFieldsValue({[name]: value});

        switch (name) {
            case formInputNames.COMPANY:
                await buildingsService.getBuildingsListByOrganizationId(value);
                break;
            default:
                break;
        }
    };

    const handleChange = () => {};

    return (
        <Form
            handleSelect={handleSelect}
            cancelButton={'Відмінити'}
            submitButton={'Додати'}
            closeModal={() => dispatch(hideModalAction(SERVICES_BUILDING_ADD))}
            formInstance={form}
            title={'Додати будинок'}
            formFields={formFields}
            initialValues={initialValues}
            handleChange={handleChange}
        />
    );
}

export default React.memo(ServicesAddBuildingModal);

import {createSelector} from 'reselect';

const selectOrganizations = (state) => state.organizations;

const makeSelectOrganizationsData = () =>
    createSelector(selectOrganizations, (organizationsState) => organizationsState.organizationsData);

const makeSelectOrganizationsNames = () =>
    createSelector(selectOrganizations, (organizationsState) => organizationsState.organizationsNames);

const makeSelectOrganizationsList = () =>
    createSelector(makeSelectOrganizationsData(), (organizationsState) => {
        const {items} = organizationsState || {};

        return items;
    });

const makeSelectCities = () =>
    createSelector(selectOrganizations, (organizationsState) => {
        const {items: cities = []} = organizationsState.cities || {};

        return cities;
    });

const makeSelectActiveTab = () =>
    createSelector(selectOrganizations, (organizationsState) => organizationsState.activeTab);

const makeSelectOrganizationClipboard = () =>
    createSelector(selectOrganizations, (organizationsState) => organizationsState.organizationClipboard);

const makeSelectOrganizationRequestFilters = () =>
    createSelector(selectOrganizations, (organizationsState) => organizationsState.filters);

const makeSelectFilterCompanyTypes = () =>
    createSelector(makeSelectOrganizationsData(), (organizationData) => organizationData?.filters?.companyTypeId);

const makeSelectCompanyTypesArray = () =>
    createSelector(makeSelectOrganizationsData(), (organizationData) => organizationData?.companyTypesArray);

const makeSelectOrganizationById = (id) =>
    createSelector(makeSelectOrganizationsData(), (organizationData) => {
        const items = organizationData.items;
        const organization = items.find((item) => item.id === id);

        return organization;
    });

const makeSelectOrganizationTypeIdByName = (companyTypeName) =>
    createSelector(makeSelectCompanyTypesArray(), (companyTypeIds) => {
        const companyType = companyTypeIds.find((companyType) => companyType.value === companyTypeName);

        return companyType?.id;
    });

const makeSelectCityNameByCityID = (cityID) =>
    createSelector(makeSelectCities(), (cities = []) => {
        const citiesList = cities || [];

        const targetCityObject = citiesList.find((city) => city?.key === cityID);
        const targerCityValue = targetCityObject?.value;

        return targerCityValue;
    });

const makeSelectCityIDByCityName = (cityName = '') =>
    createSelector(makeSelectCities(), (cities) => {
        const citiesList = cities || [];
        const targetCityObject = citiesList.find((city) => city?.value === cityName);
        const targetCityID = targetCityObject?.key;

        return targetCityID;
    });

const makeSelectCurrentOrganization = () =>
    createSelector(selectOrganizations, (organizationsState) => organizationsState.currentOrganization);

const makeSelectOrganizationBuildingNames = () =>
    createSelector(selectOrganizations, (organizationsState) => organizationsState.buildingNames);

export {
    makeSelectCurrentOrganization,
    makeSelectOrganizationTypeIdByName,
    makeSelectFilterCompanyTypes,
    makeSelectCompanyTypesArray,
    makeSelectOrganizationsData,
    makeSelectOrganizationById,
    makeSelectActiveTab,
    makeSelectOrganizationsList,
    makeSelectOrganizationsNames,
    makeSelectCities,
    makeSelectCityIDByCityName,
    makeSelectCityNameByCityID,
    makeSelectOrganizationClipboard,
    makeSelectOrganizationRequestFilters,
    makeSelectOrganizationBuildingNames,
    selectOrganizations,
};

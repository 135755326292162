import './FormItem.scss';

import {Form, Input, TimePicker} from 'antd';
import cn from 'classnames';
import React, {useState} from 'react';

// import * as formInputNames from '../../../../consts/form/formInputNames';
import * as formInputNames from '../../../app/consts/form/formInputNames';
import * as formItemTypes from '../../consts/form/formItemTypes';
import useDidUpdate from '../../hooks/useDidUpdate';
import AntDatePicker from '../AntDatePicker/AntDatePicker';
import ImageUpload from '../ImageUpload/ImageUpload';
import FormCheckbox from './components/FormCheckbox';
import FormHelper from './components/FormHelper';
import FormRadioButton from './components/FormRadioButton';
import FormSelect from './components/FormSelect';

const FormItem = (props) => {
    const {
        name,
        label,
        prefix,
        suffix,
        pattern,
        form,
        required = false,
        className,
        itemClasses,
        initialValue,
        fieldLabel,
        handleRadioChange,
        handleDatePick,
        handleTimePick,
        handleSelect,
        handleChange,
        defaultValue,
        format,
        compareWith,
        checkboxOptions,
        errorMessage,
        feedbackContent,
        radioButtonOptions,
        formItemType = formItemTypes.INPUT,
        disabled,
        requireDeps,
        itemDeps,
        preserve = false,
        ...restProps
    } = props;

    const [fieldState, setFieldState] = useState({isValid: true, value: initialValue});

    const currentValue = Form.useWatch(name, form);
    const requireValue = Form.useWatch(requireDeps, form);
    const itemDepsValue = Form.useWatch(itemDeps, form);

    const isFunction = (value) => typeof value === 'function';

    const isItemRequired = isFunction(required) ? required(requireValue, itemDepsValue) : required;
    const isDisabled = isFunction(disabled) ? disabled(requireValue, itemDepsValue) : disabled;

    const isFeedbackVisible = (isItemRequired && !fieldState.isValid) || (fieldState.value && !fieldState.isValid);

    useDidUpdate(() => {
        const isSelectFormType = formItemType == formItemTypes.SELECT;

        if (!isSelectFormType) return;

        if (isItemRequired && currentValue) {
            setFieldState({isValid: currentValue, currentValue});
        }
    }, [currentValue]);

    const validateField = (_, value) => {
        if (compareWith && value) {
            const isValid = form.getFieldValue(compareWith) === value;

            setFieldState({isValid, value});

            return isValid ? Promise.resolve() : Promise.reject();
        }

        if (pattern && value) {
            const isValid = value.match(pattern);

            setFieldState({isValid, value});

            return isValid ? Promise.resolve() : Promise.reject();
        }

        setFieldState({isValid: !!value, value});

        if (!isItemRequired) return Promise.resolve();

        return value ? Promise.resolve() : Promise.reject();
    };

    const options = {
        className: cn('text-start', itemClasses),
        initialValue,
        preserve,
        name,
        help: (
            <FormHelper
                errorMessage={errorMessage}
                content={fieldState.value && feedbackContent}
                isVisible={isFeedbackVisible}
            />
        ),
        rules: [{validator: validateField}],
    };

    const contentMapper = {
        [formItemTypes.CHECKBOX]: <FormCheckbox name={name} form={form} options={checkboxOptions} {...props} />,
        [formItemTypes.DATE_PICKER]: (
            <AntDatePicker
                disabled={isDisabled}
                onChange={handleDatePick}
                form={form}
                name={name}
                placeholder={restProps?.placeholder}
                suffix={suffix}
                {...restProps}
            />
        ),
        [formItemTypes.TIME_PICKER]: (
            <TimePicker
                format={format}
                disabled={isDisabled}
                onChange={handleTimePick}
                form={form}
                name={name}
                prefixCls='ep-form-item-timepicker'
                className='h-12 w-[100%] text-16'
                placeholder={restProps?.placeholder}
                suffix={suffix}
                defaultValue={defaultValue}
                {...restProps}
            />
        ),
        [formItemTypes.SELECT]: (
            // <FormSelect
            //     fieldLabel={fieldLabel}
            //     form={form}
            //     name={name}
            //      disabled={isDisabled}
            //     //   onSelect={handleSelect}
            //     suffix={suffix}
            //     // onSelect={(value) => handleChange(value, name)}
            //     {...restProps}
            // />
            <FormSelect
                fieldLabel={fieldLabel}
                form={form}
                name={name}
                disabled={isDisabled}
                onSelect={handleSelect}
                onChange={(value) => handleChange(value, name)}
                suffix={suffix}
                {...restProps}
            />
        ),
        [formItemTypes.MULTIPLE_SELECT]: (
            <FormSelect
                mode='multiple'
                fieldLabel={fieldLabel}
                form={form}
                name={name}
                disabled={isDisabled}
                onChange={handleChange}
                suffix={suffix}
                {...restProps}
            />
        ),
        [formItemTypes.PASSWORD]: <Input.Password disabled={isDisabled} {...restProps} className='h-12' />,
        [formItemTypes.RADIO]: (
            <FormRadioButton
                initialValue={currentValue}
                disabled={isDisabled}
                name={name}
                onChange={(e) => handleRadioChange(e.target.value, name)}
                options={radioButtonOptions}
                form={form}
                suffix={suffix}
                {...restProps}
            />
        ),
        [formItemTypes.INPUT]: (
            <Input
                title={currentValue}
                size='large'
                disabled={isDisabled}
                prefix={!!prefix && <span>{prefix}</span>}
                suffix={!isDisabled && suffix}
                className='h-12'
                onChange={(e) => handleChange?.(e.target.value, name)}
                {...restProps}
            />
        ),
        [formItemTypes.IMAGE]: <ImageUpload name={name} form={form} />,
    };

    const content = contentMapper[formItemType] || contentMapper[formItemTypes.INPUT];

    const labelComponent = (
        <label htmlFor={name} className={`text-start block mb-0.5 font-semibold`}>
            {label}
            {isItemRequired && <span className='text-radical-red ml-0.5'>*</span>}
        </label>
    );

    return (
        <div className={cn('relative', className)}>
            {labelComponent}
            <Form.Item {...options}>{content}</Form.Item>
        </div>
    );
};

export default FormItem;

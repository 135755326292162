import {Form as AntForm} from 'antd';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import Form from '../../../../components/Form/Form';
import DeviceGroupingTypes from '../../../../consts/devices/deviceGroupingTypes.ts';
import * as formInputNames from '../../../../consts/form/formInputNames';
import deviceService from '../../../../services/device/deviceService';
import log from '../../../../services/logger/log';
import {setBuildingDataCollectors} from '../../../../state/ducks/buildings';
import {setDeviceInfo, setDevices} from '../../../../state/ducks/device';
import {makeSelectBuildingProfile} from '../../../../state/selectors/buildings';
import {makeSelectRoomProfile} from '../../../../state/selectors/rooms';
import {dispatch} from '../../../../state/store';
import helpers from '../../../../utils/helpers';
import indicatorModalService from './deviceModalService';

const {useForm} = AntForm;

const DeviceModal = (props) => {
    const [form] = useForm();
    const {modalType, onCancel, onOk, buildingId: propBuildingID, roomId: propRoomID, isEditing} = props;

    const {buildingID: stateBuildingID} = useSelector(makeSelectBuildingProfile()) || {};
    const {roomID: stateRoomID} = useSelector(makeSelectRoomProfile()) || {};

    const buildingID = propBuildingID || stateBuildingID;
    const roomID = propRoomID || stateRoomID;

    const {formFields, title, submitButton, cancelButton, initialValues, requireDeps} =
        indicatorModalService.getFormContent(modalType, buildingID, roomID, isEditing) || {};

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }

        return () => {
            dispatch(setDeviceInfo(null));
            dispatch(setDevices(null));
            dispatch(setBuildingDataCollectors(null));
        };
    }, []);

    const onFinish = async () => {
        try {
            const values = await form.validateFields();

            let formValues = values;

            if (initialValues) {
                formValues = {...initialValues, ...values};
            }

            helpers.runFunction(onOk, formValues);
        } catch (error) {
            log.error('Validate Failed:', error);
        }
    };

    const resetFormFields = (fieldNames) => {
        const fieldsToReset = {};

        fieldNames.forEach((name) => (fieldsToReset[name] = null));
        form.setFieldsValue(fieldsToReset);
    };

    const setFormField = (name, key, value) => form.setFieldsValue({[name]: {id: key, value}});

    const handleSelect = async (_, name, option) => {
        const currentValue = form.getFieldValue(name);

        if (currentValue?.value === option.value) return;
        const groupID = option.groupID || null;

        setFormField(name, option.key, option.value, groupID);

        switch (name) {
            case formInputNames.DEVICE_TYPE_ID: {
                const selectedDeviceGroupID = option.key;

                const deviceModelOptions = await deviceService.getDevicesModelsByGroupID(selectedDeviceGroupID);

                resetFormFields([
                    formInputNames.DEVICE_MODEL_ID,
                    formInputNames.FACTORY_ID,
                    formInputNames.WATER_DEVICE_ID,
                ]);

                form.setFieldsValue({
                    [formInputNames.DEVICE_MODEL_ID]: undefined,
                });

                form.setFields([
                    {
                        name: formInputNames.DEVICE_MODEL_ID,
                        value: undefined,
                        props: {options: deviceModelOptions},
                    },
                ]);
                break;
            }

            case formInputNames.PZPD_ID:
            case formInputNames.GROUPING_TYPE: {
                const buildingId = form.getFieldValue(formInputNames.BUILDING_ID);
                const isDataCollectorExist = form.getFieldValue(formInputNames.PZPD_ID);
                const groupingTypeId = form.getFieldValue(formInputNames.GROUPING_TYPE)?.id;
                const dataCollectorId = form.getFieldValue(formInputNames.PZPD_ID)?.id;

                const isDistributedType = groupingTypeId === DeviceGroupingTypes.Distributed;
                const isGroupType = groupingTypeId === DeviceGroupingTypes.Grouped;

                if ((isDistributedType || isGroupType) && !roomID) {
                    resetFormFields([formInputNames.PARENT_DEVICE_ID, formInputNames.ROOM_ID]);
                }

                if (isDistributedType || isGroupType) {
                    resetFormFields([formInputNames.PARENT_DEVICE_ID]);
                }

                if ((isDistributedType || isGroupType) && isDataCollectorExist) {
                    await deviceService.getDevices({buildingId, groupingType: groupingTypeId, dataCollectorId});
                }

                if ((!isDistributedType || isGroupType) && isEditing) {
                    resetFormFields([formInputNames.PARENT_DEVICE_ID, formInputNames.ROOM_ID]);
                }

                if (!isDistributedType && !isGroupType && !isEditing) {
                    resetFormFields([formInputNames.PARENT_DEVICE_ID, formInputNames.ROOM_ID]);
                }
                break;
            }
        }
    };

    const handleChange = () => {};

    return (
        <Form
            requireDeps={requireDeps}
            handleSelect={handleSelect}
            closeModal={onCancel}
            initialValues={initialValues}
            submitButton={submitButton}
            onFinish={onFinish}
            title={title}
            formFields={formFields}
            cancelButton={cancelButton}
            formInstance={form}
            handleChange={handleChange}
        />
    );
};

export default React.memo(DeviceModal);

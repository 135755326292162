import dayjs from 'dayjs'; // Assuming dayjs is already installed
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

const XLSX = require('xlsx');

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const generatePdfWithPdfMake = (dataForExcelAndPdf, filename) => {
    const cleanData = dataForExcelAndPdf.map((item) => ({
        BuildingID: item.BuildingID,
        BuildingAddress: item.BuildingAddress,
        DeviceID: item.DeviceID,
        DeviceNumber: item.DeviceNumber,
        PersonalAccount: item.PersonalAccount,
        PreviousValue: item.PreviousValue,
        PreviousUnit: item.PreviousUnit,
        Value: item.Value,
        Unit: item.Unit,
        Error: item.Error || null,
        Date: dayjs(item.Date).isValid() ? dayjs(item.Date).format('YYYY-MM-DD HH:mm:ss') : 'N/A', // Format Date
        LocalDate: dayjs(item.LocalDate).isValid() ? dayjs(item.LocalDate).format('YYYY-MM-DD HH:mm:ss') : 'N/A', // Format LocalDate
        Status: item.Status,
    }));

    const documentDefinition = {
        pageOrientation: 'landscape', // Set landscape orientation
        pageMargins: [10, 10, 10, 10],
        content: [
            {text: 'KTE Statistics', style: 'header'},
            {
                table: {
                    headerRows: 1,
                    widths: [
                        'auto',
                        'auto',
                        'auto',
                        'auto',
                        'auto',
                        'auto',
                        'auto',
                        'auto',
                        'auto',
                        'auto',
                        'auto',
                        'auto',
                        'auto',
                    ], // Adjust column widths
                    body: [
                        [
                            {text: 'ID будинку', bold: true},
                            {text: 'Адреса будинку', bold: true},
                            {text: 'ID лічильника', bold: true},
                            {text: 'Номер лічильника', bold: true},
                            {text: 'PersonalAccount', bold: true},
                            {text: 'Попередні значення', bold: true},
                            {text: 'Од. вим.', bold: true},
                            {text: 'Значення', bold: true},
                            {text: 'Од. вим.', bold: true},
                            {text: 'Помилка', bold: true},
                            {text: 'Date', bold: true},
                            {text: 'LocalDate', bold: true},
                            {text: 'Статус', bold: true},
                        ],
                        // Data Rows
                        ...cleanData.map((item) => [
                            item.BuildingID,
                            item.BuildingAddress,
                            item.DeviceID,
                            item.DeviceNumber,
                            item.PersonalAccount,
                            item.PreviousValue,
                            item.PreviousUnit,
                            item.Value,
                            item.Unit,
                            item.Error,
                            item.Date,
                            item.LocalDate,
                            item.Status,
                        ]),
                    ],
                },
                fontSize: 8,
            },
        ],
        styles: {
            header: {
                fontSize: 14,
                bold: true,
            },
        },
        defaultStyle: {
            fontSize: 8,
        },
    };

    pdfMake.createPdf(documentDefinition).download(`${filename}.pdf`);
};

export const buildExcelFile = (dataForExcel, filename) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(dataForExcel);

    // Adjust column widths based on the content
    const columnWidths = Object.keys(dataForExcel[0]).map((key) => {
        const maxLength = Math.max(
            key.length,
            ...dataForExcel.map((row) => (row[key] ? row[key].toString().length : 0))
        );

        return {wch: maxLength + 2};
    });

    worksheet['!cols'] = columnWidths;

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Statistics');
    XLSX.writeFile(workbook, filename);
};

export const buildAllHistoryExcelFile = (dataForExcel, filename) => {
    if (!dataForExcel || dataForExcel.length === 0) {
        console.error('No data provided to buildExcelFile');
        return;
    }

    const workbook = XLSX.utils.book_new();

    const maxRowsPerSheet = 5000;

    const maxCellTextLength = 32767;

    const groupedByDate = dataForExcel.reduce((acc, row) => {
        const dateKey = row.Date || 'Unknown';

        if (!acc[dateKey]) acc[dateKey] = [];

        acc[dateKey].push(row);

        return acc;
    }, {});

    const truncateText = (text) => {
        if (!text) return '';

        return text.length > maxCellTextLength ? text.substring(0, maxCellTextLength - 3) + '...' : text;
    };

    Object.entries(groupedByDate).forEach(([date, rows], index) => {
        const chunks = [];

        for (let i = 0; i < rows.length; i += maxRowsPerSheet) {
            chunks.push(rows.slice(i, i + maxRowsPerSheet));
        }

        chunks.forEach((chunk, chunkIndex) => {
            const processedChunk = chunk.map((row) => {
                const processedRow = {};

                Object.keys(row).forEach((key) => {
                    processedRow[key] = typeof row[key] === 'string' ? truncateText(row[key]) : row[key];
                });
                return processedRow;
            });

            const worksheet = XLSX.utils.json_to_sheet(processedChunk);

            const columnWidths = Object.keys(processedChunk[0]).map((key) => {
                const maxLength = Math.max(
                    key.length,
                    ...processedChunk.map((row) => (row[key] ? row[key].toString().length : 0))
                );

                return {wch: maxLength + 2};
            });

            worksheet['!cols'] = columnWidths;

            const sheetName = `${date}_${chunkIndex + 1}`;

            XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
        });
    });

    XLSX.writeFile(workbook, filename);
};

export const structureDataForExcel = (data) => {
    const results = [];

    data.Results.forEach((result) => {
        let isFirstEntryForBuilding = true;

        const pushDeviceData = (device, status) => {
            results.push({
                BuildingID: isFirstEntryForBuilding ? result.BuildingID : '',
                BuildingAddress: isFirstEntryForBuilding ? result.BuildingAddress : '',
                DeviceID: device.DeviceID,
                DeviceNumber: device.DeviceNumber,
                PersonalAccount: device.PersonalAccount,
                Indicator: device.Indicator,
                PreviousValue: device.PreviousValue,
                PreviousUnit: device.PreviousUnit,
                Value: device.Value,
                Unit: device.Unit,
                Error: device.Error || null,
                Date: device.Date,
                LocalDate: device.LocalDate,
                Status: status,
            });
            isFirstEntryForBuilding = false; // Set to false after the first device is processed
        };

        result.FailedDevices.forEach((device) => pushDeviceData(device, 'Failed'));
        result.SucceedDevices.forEach((device) => pushDeviceData(device, 'Succeeded'));
    });

    return results;
};

const downloadFileByPath = async (path, name) => {
    return fetch(path)
        .then((transfer) => transfer.blob())
        .then((bytes) => {
            const elm = document.createElement('a');

            const filename = /[^/]*$/.exec(path);

            elm.href = URL.createObjectURL(bytes);
            elm.setAttribute('download', name || filename[0]);
            elm.click();

            return true;
        })
        .catch((error) => {
            console.log(error);
        });
};

const JSONToTextFile = (obj, filename) => {
    const blob = new Blob([JSON.stringify(obj, null, 2)], {
        type: 'text/plain;charset=utf-8',
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.href = url;
    a.download = `${filename}.txt`;
    a.click();
    URL.revokeObjectURL(url);
};

export default {
    downloadFileByPath,
    JSONToTextFile,
};

import {Form as AntForm} from 'antd';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import Form from '../../../../components/Form/Form';
import * as formInputNames from '../../../../consts/form/formInputNames';
import * as localizationKeys from '../../../../consts/localization/localizationKeys';
import {SERVICES_CONFIGURE_LVK} from '../../../../consts/modals/modalTypes';
import buildingsService from '../../../../services/buildings/buildingsService';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import organizationsService from '../../../../services/organizations/organizationsService';
import serviceLvkService from '../../../../services/services/serviceLvkService';
import {hideModalAction} from '../../../../state/ducks/modal';
import {makeSelectCurrentUserInfo} from '../../../../state/selectors/currentUser';
import {dispatch} from '../../../../state/store';
import helpers from '../../../../utils/helpers';
import configureServiceLvkModalService from './configureServiceLvkModalService';

const ConfigureServiceLvkModal = (props) => {
    const {onOk} = props || {};

    const localizedStrings = getLocalizedStrings();
    const currentUserInfo = useSelector(makeSelectCurrentUserInfo());
    const {userResponsibilities} = currentUserInfo || {};

    const [responsibility] = userResponsibilities || [];
    const [form] = AntForm.useForm();
    const {initialValues, formFields} = configureServiceLvkModalService.getFormContent(form, responsibility);

    const handleSelect = async (value, name) => {
        const currentFieldValue = form.getFieldValue(name);

        if (currentFieldValue === value) return;

        form.setFieldsValue({[name]: value});

        switch (name) {
            case formInputNames.COMPANY:
                await buildingsService.getBuildingsListByOrganizationId(value);
                break;
            default:
                break;
        }
    };

    // const fetchModalData = () => {
    //     organizationsService.getOrganizationsNames(responsibility?.cityId);
    //     serviceLvkService.getLvkSettings();
    // };

    // useEffect(() => {
    //     fetchModalData();

    //     if (initialValues) {
    //         form.setFieldsValue(initialValues);
    //     }
    // }, []);

    const [didFetch, setDidFetch] = useState(false);

    const fetchModalData = async () => {
        if (didFetch) return; // Якщо вже викликали, не виконуємо

        try {
            await organizationsService.getOrganizationsNames(responsibility?.cityId);
            await serviceLvkService.getLvkSettings();

            console.log('Fetched modal data');

            if (initialValues) {
                form.setFieldsValue(initialValues);
            }

            setDidFetch(true); // Помічаємо, що дані завантажено
        } catch (error) {
            console.error('Error in fetchModalData:', error);
        }
    };

    useEffect(() => {
        fetchModalData();
    }, [initialValues, didFetch]);

    const handleSubmit = async () => {
        const values = await form.validateFields();

        helpers.runFunction(onOk, values);
    };

    const handleChange = (value, name) => {
        if (!value || !name) {
            console.error('Value or name is undefined:', {value, name});
            return;
        }
        switch (name) {
            case formInputNames.SPECIFIC_DAYS_OF_MONTH: {
                const scheduleOptions = formFields.find((field) => field.name === formInputNames.SCHEDULE_DATE);

                if (scheduleOptions) {
                    scheduleOptions.requestOptions.options = scheduleOptions.requestOptions.options.map((option) => {
                        if (option.label === 'День/дні місяця') {
                            return {
                                ...option,
                                key: `0 59 21 ${form.getFieldValue(formInputNames.SPECIFIC_DAYS_OF_MONTH)} * ?`,
                            };
                        }
                        return option;
                    });
                }
                form.setFieldsValue({
                    [formInputNames.SCHEDULE_DATE]: `0 59 21 ${value} * ?`,
                    [formInputNames.SPECIFIC_DAY_OF_WEEK]: '',
                });

                break;
            }
            case formInputNames.SPECIFIC_DAY_OF_WEEK: {
                const scheduleOptions = formFields.find((field) => field.name === formInputNames.SCHEDULE_DATE);

                if (scheduleOptions) {
                    scheduleOptions.requestOptions.options = scheduleOptions.requestOptions.options.map((option) => {
                        if (option.label === 'День/дні тижня') {
                            return {
                                ...option,
                                key: `0 59 21 ? * ${form.getFieldValue(formInputNames.SPECIFIC_DAY_OF_WEEK)}`,
                            };
                        }
                        return option;
                    });
                }

                form.setFieldsValue({
                    [formInputNames.SCHEDULE_DATE]: `0 59 21 ? * ${form.getFieldValue(
                        formInputNames.SPECIFIC_DAY_OF_WEEK
                    )}`,
                    [formInputNames.SPECIFIC_DAYS_OF_MONTH]: '',
                });
                break;
            }
            default: {
                form.setFieldsValue({[name]: value});
            }
        }
    };

    return (
        <Form
            onFinish={handleSubmit}
            handleSelect={handleSelect}
            cancelButton={localizedStrings[localizationKeys.SERVICE_CONFIGURE_LVK_FORM_CANCEL_BUTTON]}
            submitButton={localizedStrings[localizationKeys.SERVICE_CONFIGURE_LVK_FORM_SUBMIT_BUTTON]}
            closeModal={() => dispatch(hideModalAction(SERVICES_CONFIGURE_LVK))}
            formInstance={form}
            title={localizedStrings[localizationKeys.SERVICE_CONFIGURE_LVK_FORM_TITLE]}
            formFields={formFields}
            initialValues={initialValues}
            handleChange={handleChange}
        />
    );
};

export default React.memo(ConfigureServiceLvkModal);

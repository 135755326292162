const PHONE_REGEX = /^[0-9]{12}$/;
const PHONE_REGEX_PLUS = /^\+[0-9]{12}$/;

const EMAIL_REGEX = /^(?=[a-zA-Z0-9])[\w.-]{5,29}@[a-z0-9-.]+$/;

const PASSWORD_REGEX = /^(?=.*[a-zA-Z])[A-Za-z0-9~`!@#$%^&*()-_+={[\]}|/:;'"<>,.?;]{8,20}$/;

const FIRST_LAST_NAME_REGEX =
    /^(?=.{1,30}$)[А-ЩЬЮЯҐЄІЇ]['а-щьюяґєії]*(?:[а-щьюяґєії]-[А-ЩЬЮЯҐЄІЇ]['а-щьюяґєії]*)?[а-щьюяґєії]$/;

const APARTMENT_REGEX =
    /^[1-9][0-9]{0,2}(?:(?:[А-ЩЬЮЯҐЄІЇа-щьюяґєії]{1}|[/]+([1-9]|[А-ЩЬЮЯҐЄІЇа-щьюяґєії]|[1-9][1-9А-ЩЬЮЯҐЄІЇа-щьюяґєії])))?$/;

const COMPANY_REGEX = /^(?=.{1,45}$)[^/;][А-ЩЬЮЯҐЄІЇа-щьюяґєії"-'/<>;]*(?:-[А-ЩЬЮЯҐЄІЇа-щьюяґєії"-'/<>;]*)?[^/-]{1}$/;

const ORGANIZATION_REGEX = /^[a-zA-Zа-яА-ЯіІїЇєЄ0-9,./'"\- ]*$/;

const ADDRESS_REGEX = /^[a-zA-Zа-яА-ЯіІїЇєЄ0-9,./'"\- ]*$/;

const BASE_64_REGEX = /data:.*base64,/;

const ULC_REGEX = /^\s*\d+\s*(,\s*\d+\s*)*(,)?\s*$/;

const DAY_OF_MONTH_REGEX = /^(?:[1-9]|1\d|2[0-8])(, ?(?:[1-9]|1\d|2[0-8]))*$/;

const CITY_NAME_REGEX = /^[А-ЩЬЮЯҐЄІЇ][а-щьюяґєії'ʼ]*(?:[- ][А-ЩЬЮЯҐЄІЇ][а-щьюяґєії'ʼ]*?)*$/;

const DAY_OF_WEEK_REGEX = /^(?:MON|TUE|WED|THU|FRI|SAT|SUN)(, ?(?:MON|TUE|WED|THU|FRI|SAT|SUN))*$/i;

export default {
    PHONE_REGEX,
    PHONE_REGEX_PLUS,
    EMAIL_REGEX,
    PASSWORD_REGEX,
    FIRST_LAST_NAME_REGEX,
    APARTMENT_REGEX,
    COMPANY_REGEX,
    ORGANIZATION_REGEX,
    ADDRESS_REGEX,
    BASE_64_REGEX,
    ULC_REGEX,
    DAY_OF_MONTH_REGEX,
    CITY_NAME_REGEX,
    DAY_OF_WEEK_REGEX,
};

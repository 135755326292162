const BACKEND_PORT = window.ENV?.REACT_APP_ENERGY_PORTAL_BACKEND_URL || 'http://localhost:8001';
const COLLECTION_PORT = window.ENV?.REACT_APP_ENERGY_PORTAL_DATA_COLLECTION_URL || 'http://localhost:8002';
const REPORTS_PORT = window.ENV?.REACT_APP_ENERGY_PORTAL_REPORTS_URL || 'http://localhost:8003';
const MODULES_HOST_PORT = window.ENV?.REACT_APP_ENERGY_PORTAL_MODULES_HOST_URL || 'http://localhost:8004';

export default {
    app: {
        apiUrl: `${BACKEND_PORT}/api`,
        dataCollectionApiUrl: `${COLLECTION_PORT}/api`,
        reportsApiUrl: `${REPORTS_PORT}/api`,
        modulesHostApiUrl: `${MODULES_HOST_PORT}/api`,
    },
    dataCollection: {
        maxIntervalMinutes: 60,
        minIntervalMinutes: 5,
        stepMinutes: 5,
    },
    upload: {
        supportedImageTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'],
    },
    image: {
        authBackground: 'bg-5',
    },
    durations: {
        notification: 4.5,
        searchDelay: 500,
    },
    dates: {
        minYearDate: 1970,
    },
    logs: {
        isDebug: true,
        logLevel: 'trace',
        antdWarningsEnabled: false,
    },
};

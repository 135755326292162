import dayjs from 'dayjs';

import * as apiMethods from '../../consts/api/apiMethods';
import {
    setKteStatus,
    setKyivEnergoData,
    setKyivEnergoSettingsData,
    setKyivEnergoStatisticsData,
} from '../../state/ducks/services';
import ApiClient from '../api/clients/apiClient';
import ModulesHostApiClient from '../api/clients/modulesHostApiClient';
import {
    buildAllHistoryExcelFile,
    buildExcelFile,
    generatePdfWithPdfMake,
    structureDataForExcel,
} from '../download/downloadService';
import log from '../logger/log';

//For error, have to rewrite it
const showNotification = (message) => {
    let notification = document.getElementById('notification-center');

    if (!notification) {
        notification = document.createElement('div');
        notification.id = 'notification-center';
        document.body.appendChild(notification);
    }

    notification.style.position = 'fixed';
    notification.style.top = '20px';
    notification.style.left = '50%';
    notification.style.transform = 'translateX(-50%)';
    notification.style.backgroundColor = '#f44336';
    notification.style.color = '#fff';
    notification.style.padding = '15px 30px';
    notification.style.borderRadius = '8px';
    notification.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
    notification.style.fontSize = '16px';
    notification.style.fontWeight = 'bold';
    notification.style.textAlign = 'center';
    notification.style.zIndex = '10000';

    notification.textContent = message;

    setTimeout(() => {
        notification.remove();
    }, 3000);
};

const showSuccessNotification = (message) => {
    let notification = document.getElementById('notification-center');

    if (!notification) {
        notification = document.createElement('div');
        notification.id = 'notification-center';
        document.body.appendChild(notification);
    }

    notification.style.position = 'fixed';
    notification.style.top = '20px';
    notification.style.left = '50%';
    notification.style.transform = 'translateX(-50%)';
    notification.style.backgroundColor = '#4caf50';
    notification.style.color = '#fff';
    notification.style.padding = '15px 30px';
    notification.style.borderRadius = '8px';
    notification.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
    notification.style.fontSize = '16px';
    notification.style.fontWeight = 'bold';
    notification.style.textAlign = 'center';
    notification.style.zIndex = '10000';

    notification.textContent = message;

    setTimeout(() => {
        notification.remove();
    }, 3000); // Сповіщення зникне через 3 секунди
};

const showLoadingNotification = (message) => {
    let notification = document.getElementById('notification-center');

    if (!notification) {
        notification = document.createElement('div');
        notification.id = 'notification-center';
        document.body.appendChild(notification);
    }

    notification.style.position = 'fixed';
    notification.style.top = '20px';
    notification.style.left = '50%';
    notification.style.transform = 'translateX(-50%)';
    notification.style.backgroundColor = 'grey';
    notification.style.color = '#fff';
    notification.style.padding = '15px 30px';
    notification.style.borderRadius = '8px';
    notification.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
    notification.style.fontSize = '16px';
    notification.style.fontWeight = 'bold';
    notification.style.textAlign = 'center';
    notification.style.zIndex = '10000';

    notification.textContent = message;

    setTimeout(() => {
        notification.remove();
    }, 3000); // Сповіщення зникне через 3 секунди
};

const getKyivEnergoBuildingLists = async () => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_KYIVENERGO_BUILDING_LISTS,
            action: setKyivEnergoData,
        });
    } catch (e) {
        log.error(`getBuildingsList: ${e}`);
    }
};

const getKyivEnergoBuildingListsForModal = async () => {
    try {
        const result = await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_KYIVENERGO_BUILDING_LISTS,
            action: setKyivEnergoData,
        });

        return result;
    } catch (e) {
        log.error(`getBuildingsList: ${e}`);
    }
};

const addBuildingToApplied = async ({data, onRequestSuccess}) => {
    await new ApiClient().callPost({
        methodName: apiMethods.SERVICES_KYIVENERGO_BUILDING_LISTS,
        requestConfig: {
            data,
        },
        onResponse: onRequestSuccess,
    });
};

const getKyivEnergoSettings = async () => {
    try {
        const result = await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_KYIVENERGO_SETTINGS,
            action: setKyivEnergoSettingsData,
        });
    } catch (e) {
        log.error(`getKyivEnergoSettings: ${e}`);
    }
};

const getKyivEnergoSettingsForModal = async () => {
    try {
        const result = await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_KYIVENERGO_SETTINGS,
            action: setKyivEnergoSettingsData,
        });

        return result;
    } catch (e) {
        log.error(`getKyivEnergoSettings: ${e}`);
    }
};

const updateKyivEnergoSettings = async ({data, onRequestSuccess}) => {
    await new ApiClient().callPost({
        methodName: apiMethods.SERVICES_KYIVENERGO_SETTINGS,
        requestConfig: {
            data,
        },
        onResponse: onRequestSuccess,
    });

    await new ModulesHostApiClient().callPost({
        methodName: 'schedulers/kte ',
    });
};

const updateSchedule = async () => {
    await new ModulesHostApiClient().callPost({
        methodName: 'schedulers/kte',
    });
};

const getKyivEnergoStatistics = async () => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_KYIVENERGO_STATISTICS,
            action: setKyivEnergoStatisticsData,
        });
    } catch (e) {
        log.error(`getKyivEnergoStatistics: ${e}`);
    }
};

// const getJsonKteStatistics = async () => {
//     try {
//         const response = await new ApiClient().callGet({
//             methodName: apiMethods.SERVICES_KTE_DOWNLOAD_STATISTICS,
//         });
//         const getDate = (dateString) => {
//             return dateString ? dayjs(dateString).format('YYYY-MM-DD') : '-';
//         };

//         const date = getDate(response.Date);
//         const filename = `KTE_${date}`;

//         const isDownloaded = await downloadService.JSONToTextFile(response, filename);

//         return response;
//     } catch (e) {
//         log.error(`getJsonKteStatistics: ${e}`);
//     }
// };

const getExcelKteStatistics = async () => {
    try {
        const response = await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_KTE_DOWNLOAD_STATISTICS,
        });

        const getDate = (dateString) => {
            return dateString ? dayjs(dateString).format('YYYY-MM-DD') : '-';
        };

        console.log('getExcelKteStatistics: ', response);

        const date = getDate(response.Date);
        const filename = `KTE_${date}.xlsx`;

        const dataForExcel = structureDataForExcel(response);

        // Use the new buildExcelFile function to create the Excel file
        buildExcelFile(dataForExcel, filename);

        return response;
    } catch (e) {
        log.error(`getExcelKteStatistics: ${e}`);
    }
};

const getHistoryExcelKteStatistics = async (startDate, endDate) => {
    try {
        console.log('Start Date:', startDate);
        console.log('End Date:', endDate);

        const response = await new ApiClient().callGet({
            methodName: 'modules/kte/statistics/history/download',
            requestConfig: {
                params: {startDate, endDate},
            },
        });

        if (!response?.Statistics || response.Statistics.length === 0) {
            console.warn('No statistics data found in the response');

            showNotification('Статистика за вибраний період відсутня.', 'warning');
            return;
        }

        const formatDate = (dateString) => (dateString ? dayjs(dateString).format('YYYY-MM-DD') : '-');

        const fileName = `KTEHistory_${formatDate(new Date().toISOString())}.xlsx`;

        const dataForExcel = response.Statistics.flatMap((stat) =>
            stat.Results.flatMap((result) => {
                return result.FailedDevices.map((device) => ({
                    BuildingID: result.BuildingId || 'N/A',
                    BuildingAddress: result.BuildingAddress || 'N/A',
                    DeviceID: device.DeviceID || 'N/A',
                    DeviceNumber: device.DeviceNumber || 'N/A',
                    PersonalAccount: device.PersonalAccount || 'N/A',
                    Indicator: device.Indicator || 'N/A',
                    PreviousValue: device.PreviousValue || 'N/A',
                    PreviousUnit: device.PreviousUnit || 'N/A',
                    Value: device.Value || 'N/A',
                    Unit: device.Unit || 'N/A',
                    Error: device.Error || 'N/A',
                    Date: formatDate(stat.Date),
                    LocalDate: device.LocalDate || 'N/A',
                    Status: stat.Status || 'N/A',
                }));
            })
        );

        console.log('Data prepared for Excel:', dataForExcel);

        buildAllHistoryExcelFile(dataForExcel, fileName);

        console.log(`Excel file "${fileName}" created successfully.`);

        return response;
    } catch (e) {
        console.error(`Error in getHistoryExcelKteStatistics: ${e.message}`, e);
        return null;
    }
};

const getBuildingStatisticsHistory = async (buildingId, startDate, endDate) => {
    console.log('API Call Params:', {buildingId, startDate, endDate});
    try {
        const response = await new ApiClient().callGet({
            methodName: `modules/kte/statistics/history/download/building/${buildingId}`,
            requestConfig: {
                params: {
                    startDate,
                    endDate,
                },
            },
        });

        console.log('Response:', response);

        if (!response?.buildingResults || response.buildingResults.length === 0) {
            console.warn('No statistics data found in the response');
            showNotification('Статистика за вибраний період відсутня.', 'warning');
            return;
        }

        const formatDate = (dateString) => (dateString ? dayjs(dateString).format('YYYY-MM-DD') : 'N/A');

        const dataForExcel = response.buildingResults.flatMap((building) => {
            const failedDevices = (building.Result.FailedDevices || []).map((device) => ({
                Date: formatDate(building.EventTs),
                EventDate: building.EventTs || 'N/A',
                BuildingID: building.Result.BuildingId || 'N/A',
                BuildingAddress: building.Result.BuildingAddress || 'N/A',
                DeviceType: 'FailedDevice',
                DeviceID: device.DeviceID || 'N/A',
                DeviceNumber: device.DeviceNumber || 'N/A',
                Error: device.Error || 'N/A',
                Indicator: device.Indicator || 'N/A',
                LocalDate: device.LocalDate || 'N/A',
                PersonalAccount: device.PersonalAccount || 'N/A',
                PreviousUnit: device.PreviousUnit || 'N/A',
                PreviousValue: device.PreviousValue || 'N/A',
                Unit: device.Unit || 'N/A',
                Value: device.Value || 'N/A',
            }));

            const succeedDevices = (building.Result.SucceedDevices || []).map((device) => ({
                Date: formatDate(building.EventTs),
                EventDate: building.EventTs || 'N/A',
                BuildingID: building.Result.BuildingId || 'N/A',
                BuildingAddress: building.Result.BuildingAddress || 'N/A',
                DeviceType: 'SucceedDevice',
                DeviceID: device.DeviceID || 'N/A',
                DeviceNumber: device.DeviceNumber || 'N/A',
                Indicator: device.Indicator || 'N/A',
                LocalDate: device.LocalDate || 'N/A',
                PersonalAccount: device.PersonalAccount || 'N/A',
                PreviousUnit: device.PreviousUnit || 'N/A',
                PreviousValue: device.PreviousValue || 'N/A',
                Unit: device.Unit || 'N/A',
                Value: device.Value || 'N/A',
            }));

            return [...failedDevices, ...succeedDevices];
        });

        console.log('Data prepared for Excel:', dataForExcel);

        if (dataForExcel.length > 0) {
            const fileName = `KTEBuilding_${formatDate(new Date().toISOString())}.xlsx`;

            buildAllHistoryExcelFile(dataForExcel, fileName);
            console.log(`Excel file "${fileName}" created successfully.`);
        } else {
            console.warn('No valid data to write to Excel');
        }

        return response;
    } catch (e) {
        console.error(`Error in getBuildingStatisticsHistory: ${e.message}`, e);
        throw e;
    }
};

const getPdfKteStatistics = async () => {
    try {
        const response = await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_KTE_DOWNLOAD_STATISTICS,
        });

        const getDate = (dateString) => {
            return dateString ? dayjs(dateString).format('YYYY-MM-DD') : '-';
        };

        const date = getDate(response.Date);
        const filename = `KTE_${date}`;

        const dataForExcelAndPdf = structureDataForExcel(response);

        // Generate PDF file
        generatePdfWithPdfMake(dataForExcelAndPdf, `${filename}`);

        return response;
    } catch (e) {
        log.error(`getJsonKteStatistics: ${e}`);
    }
};

const getKteStatus = async () => {
    try {
        await new ApiClient().callGet({
            methodName: apiMethods.SERVICES_KTE_STATE,
            action: setKteStatus,
        });
    } catch (e) {
        log.error(`getKteStatus: ${e}`);
    }
};

const turnOffKteDataSending = async ({onRequestSuccess}) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.SERVICES_KTE_OFF,
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`offKte: ${e}`);
    }
};

const turnOnKteDataSending = async ({onRequestSuccess}) => {
    try {
        await new ApiClient().callPost({
            methodName: apiMethods.SERVICES_KTE_ON,
            onResponse: onRequestSuccess,
        });
    } catch (e) {
        log.error(`onKte: ${e}`);
    }
};

const executeKte = async () => {
    try {
        const state = await new ModulesHostApiClient().callGet({
            methodName: 'schedulers/kte',
        });

        if (state.status === 1) {
            await new ModulesHostApiClient().callPost({
                methodName: 'schedulers/kte/execute',
            });

            showSuccessNotification('Дані успішно відправлено!');
        } else if (state.status === 2) {
            showLoadingNotification('Дані вже було відправленно!');
        } else {
            showNotification('Не можна відправити дані!');
        }
    } catch (e) {
        log.error(`execureKte: ${e} `);
    }
};

const downloadAccounts = async () => {
    try {
        const response = await new ApiClient().callGet({
            methodName: 'modules/kte/accounts',
            responseType: 'json',
        });

        const dataForExcel = Object.entries(response).flatMap(([ulcKey, accounts]) =>
            accounts.map((account) => ({
                ULCKey: ulcKey,
                CODE: account.CODE,
                COUNTERID: account.COUNTERID,
                COUNTERNUMBER: account.COUNTERNUMBER,
                ISSEVERALTARIF: account.ISSEVERALTARIF,
                LASTPOKAZ1: account.LASTPOKAZ1,
                LASTPOKAZDATE: account.LASTPOKAZDATE,
                MSG: account.MSG,
                SERVNAME: account.SERVNAME,
                TYPEUNIT: account.TYPEUNIT,
                ULCADDRESS: account.ULCADDRESS,
                ULCNUMBER: account.ULCNUMBER,
            }))
        );

        console.log('Data for Excel: ', dataForExcel);

        const filename = 'ActivePersonalAccounts.xlsx';

        buildExcelFile(dataForExcel, filename);
    } catch (e) {
        console.error('Error downloading or converting file', e);
    }
};

export default {
    getKyivEnergoBuildingLists,
    addBuildingToApplied,
    getKyivEnergoSettings,
    updateKyivEnergoSettings,
    getKyivEnergoStatistics,
    getExcelKteStatistics,
    getPdfKteStatistics,
    getKteStatus,
    turnOffKteDataSending,
    turnOnKteDataSending,
    executeKte,
    getHistoryExcelKteStatistics,
    getBuildingStatisticsHistory,
    downloadAccounts,
    getKyivEnergoBuildingListsForModal,
    updateSchedule,
    getKyivEnergoSettingsForModal,
};

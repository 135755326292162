import {Col, Divider, Form as AntForm, Row} from 'antd';
import cn from 'classnames';
import React from 'react';

import AntButton from '../AntButton/AntButton';
import FormItem from '../FormItem/FormItem';

const Form = (props) => {
    const {
        initialValues,
        onFinish,
        formFields,
        title,
        cancelButton,
        submitButton,
        closeModal,
        handleRadioChange,
        handleSelect,
        handleChange,
        handleDatePick,
        formInstance,
        customButtons,
        hiddenPattern,
        requireDeps,
        containerClassName,
        formClassName,
        buttonsClassName,
        layout = 'vertical',
    } = props;

    return (
        <AntForm
            className={formClassName}
            initialValues={initialValues}
            onFinish={onFinish}
            form={formInstance}
            layout={layout}
            name={title}
            noValidate
            onValuesChange={(changedValues, allValues) => {
                const [changedFieldName] = Object.keys(changedValues);

                handleChange?.(changedValues[changedFieldName], changedFieldName);
            }}
        >
            <div className={cn('px-[110px] pt-[42px]', containerClassName)}>
                {title && <h3 className='font-semibold text-24 text-charcoal text-center pb-11'>{title}</h3>}
                <Row gutter={40}>
                    {formFields.map(({row, name, key, hidden, ...rest}) => {
                        const fieldProps = {
                            key,
                            name,
                            handleRadioChange,
                            handleSelect,
                            handleChange,
                            handleDatePick,
                            form: formInstance,
                            requireDeps,
                            ...rest,
                        };
                        const requireValue = AntForm.useWatch(requireDeps, formInstance);
                        const itemDepsValue = AntForm.useWatch(rest.itemDeps, formInstance);

                        const isFunction = (value) => typeof value === 'function';

                        const isHidden = isFunction(hidden) ? hidden(requireValue, itemDepsValue) : hidden;

                        const colClasses = cn('w-full visible', {
                            hidden: isHidden || (hiddenPattern && hiddenPattern[name]),
                        });

                        return (
                            <Col className={colClasses} span={row ? null : 12} key={key}>
                                <FormItem {...fieldProps} />
                            </Col>
                        );
                    })}
                </Row>
            </div>
            {customButtons || (
                <>
                    <Divider className='mt-[30px] mb-[26px]' />
                    <div className={cn('flex justify-between items-center px-[55px]', buttonsClassName)}>
                        {cancelButton && (
                            <AntButton className='h-[50px]' onClick={closeModal} type='button' bordered cancelHover>
                                {cancelButton}
                            </AntButton>
                        )}
                        <AntButton className='h-[50px]' type='submit' htmlType='submit' primary>
                            {submitButton}
                        </AntButton>
                    </div>
                </>
            )}
        </AntForm>
    );
};

export default Form;

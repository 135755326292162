import {useState} from 'react';
import {useSelector} from 'react-redux';

import * as formInputNames from '../../../../consts/form/formInputNames';
import * as formItemTypes from '../../../../consts/form/formItemTypes';
import * as localizationKeys from '../../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import organizationsService from '../../../../services/organizations/organizationsService';
import {makeSelectOrganizationsNames} from '../../../../state/selectors/organizations';
import {makeSelectKvkSettingsData} from '../../../../state/selectors/services';
import helpers from '../../../../utils/helpers';
import regexUtils from '../../../../utils/regexUtils';

const styles = `
     .hidden {
         display: none !important;
     }
 `;

const getFormContent = () => {
    const localizedStrings = getLocalizedStrings();

    const settings = useSelector(makeSelectKvkSettingsData()) || {};

    const initialValues = {
        ...settings,

        [formInputNames.COMPANY]: settings?.organizationID,
        loginExUrl: settings?.loginExUrl || '',
        baseUrl: settings?.baseUrl || '',
        executeExUrl: settings?.executeExUrl || '',
        login: settings?.login || '',
        [formInputNames.PASSWORD]: settings?.password,
        // schedule: settings?.schedule || 'Розклад',
        schedule: (() => {
            if (settings?.schedule) {
                const cronParts = settings.schedule.split(' ');

                if (
                    cronParts.length === 6 &&
                    cronParts[0] === '0' &&
                    cronParts[1] === '59' &&
                    cronParts[2] === '21' &&
                    /^[0-9,]+$/.test(cronParts[3]) &&
                    cronParts[4] === '*' &&
                    cronParts[5] === '?'
                ) {
                    // return 'День/дні місяця';
                    return 'specificDaysOfMonth';
                }

                if (
                    cronParts.length === 6 &&
                    cronParts[0] === '0' &&
                    cronParts[1] === '0' &&
                    cronParts[2] === '12' &&
                    cronParts[3] === '?' &&
                    /^[A-Z,a-z,]+$/.test(cronParts[4]) &&
                    cronParts[5] === '*'
                ) {
                    return 'specificDayOfWeek';
                }
            }
            return settings?.schedule || 'Розклад';
        })(),
        specificDayOfWeek: () => {
            if (settings?.schedule) {
                const cronParts = settings.schedule.split(' ');

                if (
                    cronParts.length === 6 &&
                    cronParts[0] === '0' &&
                    cronParts[1] === '0' &&
                    cronParts[2] === '12' &&
                    cronParts[3] === '?' &&
                    /^[A-Z,a-z,]+$/.test(cronParts[4]) &&
                    cronParts[5] === '*'
                ) {
                    return cronParts[4];
                }
            }
            return '';
        },
        specificDaysOfMonth: (() => {
            if (settings?.schedule) {
                const cronParts = settings.schedule.split(' ');

                if (cronParts.length === 6 && /^[0-9,]+$/.test(cronParts[3])) {
                    return cronParts[3];
                }
            }
            return '';
        })(),
    };

    const [selectedScheduleDate, setSelectedScheduleDate] = useState(settings?.scheduleDate || '');
    const [specificDaysOfMonth, setSpecificDaysOfMonth] = useState('');
    const [specificDayOfWeek, setSpecificDayOfWeek] = useState('');

    const handleChange = (value, fieldName) => {
        if (fieldName === 'scheduleDate') {
            if (value === 'specificDaysOfMonth') {
                setSpecificDayOfWeek('');
            } else if (value === 'specificDayOfWeek') {
                setSpecificDaysOfMonth('');
            }
            setSelectedScheduleDate(value);
        }
        console.log(`Значення для ${fieldName}:`, value);
    };

    const formFields = [
        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            fieldLabel: 'name',
            required: true,
            name: formInputNames.COMPANY,
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_COMPANY_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_COMPANY_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_COMPANY_FIELD_ERROR],
            requestOptions: {
                selectRequestData: makeSelectOrganizationsNames,
                getRequestData: () => organizationsService.getOrganizationsNames(),
            },
        },
        // {
        //     key: helpers.guid(),
        //     required: true,
        //     maxLength: 5,
        //     name: 'dayOfMonth',
        //     label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_FORM_COUNTER_DATA_SEND_DAY_FIELD_LABEL],
        //     placeholder:
        //         localizedStrings[localizationKeys.SERVICE_CONFIGURE_FORM_COUNTER_DATA_SEND_DAY_FIELD_PLACEHOLDER],
        //     errorMessage: localizedStrings[localizationKeys.SERVICE_CONFIGURE_FORM_COUNTER_DATA_SEND_DAY_FIELD_ERROR],
        //     pattern: regexUtils.DAY_OF_MONTH_REGEX,
        // },
        {
            key: helpers.guid(),
            required: true,
            name: formInputNames.LOGIN,
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_FIELD_FTP_LOGIN_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_FIELD_FTP_LOGIN_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.NOTIFICATION_SERVICE_FORM_FIELD_ERROR],
        },
        {
            key: helpers.guid(),
            formItemType: formItemTypes.PASSWORD,
            name: formInputNames.PASSWORD,
            required: true,
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_FIELD_FTP_PASSWORD_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_FIELD_FTP_PASSWORD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_FIELD_FTP_PASSWORD_ERROR],
        },
        {
            key: helpers.guid(),
            required: true,
            name: 'loginExUrl',
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_LOGIN_EX_URL_FIELD_LABEL],
            maxLength: 100,
            placeholder: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_LOGIN_EX_URL_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_LOGIN_EX_URL_FIELD_ERROR],
        },
        {
            key: helpers.guid(),
            required: true,
            maxLength: 100,
            name: 'executeExUrl',
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_EXECUTE_EX_URL_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_EXECUTE_EX_URL_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_EXECUTE_EX_URL_FIELD_ERROR],
        },
        {
            key: helpers.guid(),
            row: false,
            required: true,
            name: 'baseUrl',
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_BASE_URL_FIELD_LABEL],
            maxLength: 100,
            placeholder: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_BASE_URL_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KVK_FORM_BASE_URL_FIELD_ERROR],
        },
        {
            key: 'schedule',
            name: 'schedule',
            formItemType: 'SELECT',
            label: 'Розклад відправки даних',
            onChange: (value) => handleChange(value, 'scheduleDate'),
            requestOptions: {
                options: [
                    {label: 'Щогодини', key: '0 0 * ? * *'},
                    {label: 'Щодоби', key: '0 59 21 * * ?'},
                    {label: 'Раз на тиждень', key: '0 59 21 ? * MON *'},
                    {label: 'Останній день місяця', key: '0 59 21 L * ? *'},
                    {label: 'Перший день місяця', key: '0 59 21 1 * ? *'},
                    {label: 'День/дні місяця', key: 'specificDaysOfMonth'},
                    {label: 'День/дні тижня', key: 'specificDayOfWeek'},
                ],
            },
        },
        {
            key: 'dynamicInput',
            formItemType: 'INPUT',
            name: selectedScheduleDate === 'specificDaysOfMonth' ? 'specificDaysOfMonth' : 'specificDayOfWeek',
            required: selectedScheduleDate === 'specificDaysOfMonth' || selectedScheduleDate === 'specificDayOfWeek',
            label: selectedScheduleDate === 'specificDaysOfMonth' ? 'День/дні місяця' : 'День/дні тижня',
            pattern:
                selectedScheduleDate === 'specificDaysOfMonth'
                    ? regexUtils.DAY_OF_MONTH_REGEX
                    : regexUtils.DAY_OF_WEEK_REGEX,
            placeholder: selectedScheduleDate === 'specificDaysOfMonth' ? 'Введіть дні місяця' : 'Введіть дні тижня',
            className:
                selectedScheduleDate === 'specificDaysOfMonth' || selectedScheduleDate === 'specificDayOfWeek'
                    ? ''
                    : 'hidden',
        },
    ];

    return {
        formFields,
        initialValues,
    };
};

export default {
    getFormContent,
};

import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import * as formInputNames from '../../../../consts/form/formInputNames';
import * as formItemTypes from '../../../../consts/form/formItemTypes';
import * as localizationKeys from '../../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import organizationsService from '../../../../services/organizations/organizationsService';
import {makeSelectorDeviceGroups} from '../../../../state/selectors/device';
import {makeSelectOrganizationsNames} from '../../../../state/selectors/organizations';
import {makeSelectKyivEnergoSettingsData} from '../../../../state/selectors/services';
import helpers from '../../../../utils/helpers';
import regexUtils from '../../../../utils/regexUtils';

const styles = `
      .hidden {
          display: none !important;
      }
  `;

const getFormContent = (form) => {
    const localizedStrings = getLocalizedStrings();

    const settings = useSelector(makeSelectKyivEnergoSettingsData()) || {};

    const [selectedScheduleDate, setSelectedScheduleDate] = useState(settings?.scheduleDate || '');
    const [specificDaysOfMonth, setSpecificDaysOfMonth] = useState('');
    const [specificDayOfWeek, setSpecificDayOfWeek] = useState('');
    const [scheduleOptions, setScheduleOptions] = useState([]); // Стан для опцій

    const handleChange = (value, fieldName) => {
        if (fieldName === 'scheduleDate') {
            if (value === 'specificDaysOfMonth') {
                setSpecificDayOfWeek('');
            } else if (value === 'specificDayOfWeek') {
                setSpecificDaysOfMonth('');
            }
            setSelectedScheduleDate(value);
        }
        console.log(`Значення для ${fieldName}:`, value);
    };

    const initialValues = {
        ...settings,

        [formInputNames.COMPANY]: settings?.organizationID,
        app: settings?.app || '',
        secretKey: settings?.secretKey || '',
        baseUrl: settings?.baseUrl || '',
        getActivePersonalAccountsUrl: settings?.getActivePersonalAccountsUrl || '',
        sendCountersDataUrl: settings?.sendCountersDataUrl || '',
        dayOfMonth: settings?.dayOfMonth ? String(settings?.dayOfMonth) : '',
        [formInputNames.DEVICE_GROUPS_TYPES]: settings?.indicators,
        // schedule: settings?.schedule || 'Розклад',
        // specificDayOfWeek: settings?.specificDayOfWeek || '',
        // specificDaysOfMonth: settings?.speecificDaysOfMonth || '',
        schedule: (() => {
            if (settings?.schedule) {
                const cronParts = settings.schedule.split(' ');

                if (
                    cronParts.length === 6 &&
                    cronParts[0] === '0' &&
                    cronParts[1] === '59' &&
                    cronParts[2] === '21' &&
                    /^[0-9,]+$/.test(cronParts[3]) &&
                    cronParts[4] === '*' &&
                    cronParts[5] === '?'
                ) {
                    // return 'День/дні місяця';
                    return 'specificDaysOfMonth';
                }

                if (
                    cronParts.length === 6 &&
                    cronParts[0] === '0' &&
                    cronParts[1] === '59' &&
                    cronParts[2] === '21' &&
                    cronParts[3] === '?' &&
                    cronParts[4] === '*' &&
                    /^[A-Za-z]+(,[A-Za-z]+)*$/.test(cronParts[5])
                ) {
                    return 'specificDayOfWeek';
                }
            }
            return settings?.schedule || 'Розклад';
        })(),
        specificDayOfWeek: (() => {
            if (settings?.schedule) {
                const cronParts = settings.schedule.split(' ');

                if (
                    cronParts.length === 6 &&
                    cronParts[0] === '0' &&
                    cronParts[1] === '59' &&
                    cronParts[2] === '21' &&
                    cronParts[3] === '?' &&
                    cronParts[4] === '*' &&
                    /^[A-Za-z]+(,[A-Za-z]+)*$/.test(cronParts[5])
                ) {
                    return cronParts[5];
                }
            }
            return '';
        })(),
        specificDaysOfMonth: (() => {
            if (settings?.schedule) {
                const cronParts = settings.schedule.split(' ');

                if (cronParts.length === 6 && /^[0-9,]+$/.test(cronParts[3])) {
                    return cronParts[3];
                }
            }
            return '';
        })(),
    };

    const formFields = [
        {
            key: helpers.guid(),
            formItemType: formItemTypes.SELECT,
            fieldLabel: 'name',
            required: true,
            name: formInputNames.COMPANY,
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_COMPANY_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_COMPANY_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_COMPANY_FIELD_ERROR],
            requestOptions: {
                selectRequestData: makeSelectOrganizationsNames,
                getRequestData: () => organizationsService.getOrganizationsNames(),
            },
        },

        {
            key: helpers.guid(),
            required: true,
            name: 'app',
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_APP_FIELD_LABEL],
            maxLength: 100,
            placeholder: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_APP_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_APP_FIELD_ERROR],
        },
        {
            key: helpers.guid(),
            required: true,
            name: 'secretKey',
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_SECRET_KEY_FIELD_LABEL],
            maxLength: 100,
            placeholder: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_SECRET_KEY_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_SECRET_KEY_FIELD_ERROR],
        },

        {
            key: helpers.guid(),
            required: true,
            name: 'baseUrl',
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_BASE_URL_FIELD_LABEL],
            maxLength: 100,
            placeholder: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_BASE_URL_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_BASE_URL_FIELD_ERROR],
        },
        {
            key: helpers.guid(),
            required: true,
            row: true,
            maxLength: 100,
            name: 'getActivePersonalAccountsUrl',
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_PERSONAL_ACCOUNTERS_URL_FIELD_LABEL],
            placeholder:
                localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_PERSONAL_ACCOUNTERS_URL_FIELD_PLACEHOLDER],
            errorMessage:
                localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_PERSONAL_ACCOUNTERS_URL_FIELD_ERROR],
        },
        {
            key: helpers.guid(),
            required: true,
            row: true,
            maxLength: 100,
            name: 'sendCountersDataUrl',
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_SEND_COUNTER_DATA_URL_FIELD_LABEL],
            placeholder:
                localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_SEND_COUNTER_DATA_URL_FIELD_PLACEHOLDER],
            errorMessage:
                localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_SEND_COUNTER_DATA_URL_FIELD_ERROR],
        },
        // {
        //     key: helpers.guid(),
        //     required: true,
        //     maxLength: 5,
        //     name: 'dayOfMonth',
        //     label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_FORM_COUNTER_DATA_SEND_DAY_FIELD_LABEL],
        //     placeholder:
        //         localizedStrings[localizationKeys.SERVICE_CONFIGURE_FORM_COUNTER_DATA_SEND_DAY_FIELD_PLACEHOLDER],
        //     errorMessage: localizedStrings[localizationKeys.SERVICE_CONFIGURE_FORM_COUNTER_DATA_SEND_DAY_FIELD_ERROR],
        //     pattern: regexUtils.DAY_OF_MONTH_REGEX,
        // },

        {
            key: helpers.guid(),
            name: formInputNames.DEVICE_GROUPS_TYPES,
            fieldLabel: 'value',
            fieldValue: 'key',
            formItemType: formItemTypes.MULTIPLE_SELECT,
            required: true,
            label: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_DEVICE_TYPES_FIELD_LABEL],
            placeholder: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_DEVICE_TYPES_FIELD_PLACEHOLDER],
            errorMessage: localizedStrings[localizationKeys.SERVICE_CONFIGURE_KTE_FORM_DEVICE_TYPES_FIELD_ERROR],
            withInitialRequest: true,
            requestOptions: {
                selectRequestData: makeSelectorDeviceGroups,
            },
        },
        {
            key: 'schedule',
            name: 'schedule',
            formItemType: 'SELECT',
            label: 'Розклад відправки даних',
            onChange: (value) => handleChange(value, 'scheduleDate'),
            requestOptions: {
                options: [
                    {label: 'Щогодини', key: '0 0 * ? * *'},
                    {label: 'Щодоби', key: '0 59 21 * * ?'},
                    {label: 'Раз на тиждень', key: '0 59 21 ? * MON *'},
                    {label: 'Останній день місяця', key: '0 59 21 L * ? *'},
                    {label: 'Перший день місяця', key: '0 59 21 1 * ? *'},
                    {label: 'День/дні місяця', key: 'specificDaysOfMonth'},
                    {label: 'День/дні тижня', key: 'specificDayOfWeek'},
                ],
            },
        },
        {
            key: 'dynamicInput',
            formItemType: 'INPUT',
            name: selectedScheduleDate === 'specificDaysOfMonth' ? 'specificDaysOfMonth' : 'specificDayOfWeek',
            required: selectedScheduleDate === 'specificDaysOfMonth' || selectedScheduleDate === 'specificDayOfWeek',
            label: selectedScheduleDate === 'specificDaysOfMonth' ? 'День/дні місяця' : 'День/дні тижня',
            pattern:
                selectedScheduleDate === 'specificDaysOfMonth'
                    ? regexUtils.DAY_OF_MONTH_REGEX
                    : regexUtils.DAY_OF_WEEK_REGEX,
            placeholder: selectedScheduleDate === 'specificDaysOfMonth' ? 'Введіть дні місяця' : 'Введіть дні тижня',
            className:
                selectedScheduleDate === 'specificDaysOfMonth' || selectedScheduleDate === 'specificDayOfWeek'
                    ? ''
                    : 'hidden',
        },
    ];

    return {
        formFields,
        initialValues,
    };
};

export default {
    getFormContent,
};

import {Form as AntForm} from 'antd';
import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';

import Form from '../../../../components/Form/Form';
import {ROOMS} from '../../../../consts/app/requestParamTypes';
import * as formInputNames from '../../../../consts/form/formInputNames';
import * as modalTypes from '../../../../consts/modals/modalTypes';
import buildingsService from '../../../../services/buildings/buildingsService';
import log from '../../../../services/logger/log';
import organizationsService from '../../../../services/organizations/organizationsService';
import {setBuildings, setBuildingsOrganizationId, setRoomBuildingId, setRooms} from '../../../../state/ducks/buildings';
import {setOrganizationsNames} from '../../../../state/ducks/organizations';
import {clearRequestParams} from '../../../../state/ducks/requestParams';
import {dispatch} from '../../../../state/store';
import helpers from '../../../../utils/helpers';
import userModalService from './userModalService';

const UserModal = (props) => {
    const {onOk, onCancel, modalType, roomId, responsibilityId} = props;
    const [form] = AntForm.useForm();
    const {buildingId} = useParams();

    let modalData = {};

    switch (modalType) {
        case modalTypes.ADD_USERS_TO_BUILDING_MODAL_TYPE: {
            modalData = userModalService.getAddUserToBuildingModalContent(buildingId, roomId);
            break;
        }
        case modalTypes.ADD_USERS_MODAL_TYPE: {
            modalData = userModalService.getAddUserModalContent();
            break;
        }
        case modalTypes.EDIT_USERS_MODAL_TYPE: {
            modalData = userModalService.getEditUserModalContent();
            break;
        }
        case modalTypes.ADD_USER_OBJECTS_MODAL_TYPE: {
            modalData = userModalService.getAddUserObjectsModalContent();
            break;
        }
        case modalTypes.EDIT_USER_OBJECTS_MODAL_TYPE: {
            modalData = userModalService.getEditUserObjectsModalContent(responsibilityId);
            break;
        }

        default:
            break;
    }

    const {formContent, title, submitButton, cancelButton, initialValues, requireDeps} = modalData || {};

    const onFinish = async () => {
        try {
            const values = await form.validateFields();

            let formValues = values;

            if (modalType == modalTypes.ADD_USERS_TO_BUILDING_MODAL_TYPE) {
                formValues = {...initialValues, ...values};
            }
            helpers.runFunction(onOk, formValues);
        } catch (error) {
            log.error(`UserModal - validate failed: ${error}`);
        }
    };

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }

        return () => {
            form.resetFields();
            dispatch(setRooms(null));
            dispatch(setRoomBuildingId(null));
            dispatch(setBuildings(null));
            dispatch(setOrganizationsNames(null));
        };
    }, []);

    const resetFormField = (fieldNames) => {
        const fieldsToReset = {};

        fieldNames.forEach((name) => (fieldsToReset[name] = null));
        form.setFieldsValue(fieldsToReset);
    };

    const setFormField = (name, key, value) => form.setFieldsValue({[name]: {id: key, value}});

    const handleRadioChange = (value, name) => {
        form.setFieldsValue({[name]: value});
        switch (name) {
            case formInputNames.ALL_BUILDINGS: {
                resetFormField([formInputNames.BUILDING]);
                break;
            }
            default:
                break;
        }
    };

    const handleSelect = async (value, name, option) => {
        const currentValue = form.getFieldValue(name);

        if (currentValue?.value === option.value) return;

        setFormField(name, option.key, option.value);

        switch (name) {
            case formInputNames.CITY: {
                resetFormField([formInputNames.COMPANY, formInputNames.BUILDING, formInputNames.APARTMENT]);

                await organizationsService.getOrganizationsNames(value);
                break;
            }
            case formInputNames.COMPANY: {
                resetFormField([formInputNames.BUILDING, formInputNames.APARTMENT]);

                dispatch(setBuildingsOrganizationId(value));
                await buildingsService.getBuildingsListByOrganizationId(value);
                break;
            }
            case formInputNames.BUILDING: {
                resetFormField([formInputNames.APARTMENT]);

                dispatch(clearRequestParams(ROOMS));
                dispatch(setRoomBuildingId(option?.key));
                await buildingsService.getRoomsByBuildingId(option?.key);
                break;
            }
            default:
                break;
        }
    };

    const handleChange = () => {};

    return (
        <Form
            handleRadioChange={handleRadioChange}
            handleSelect={handleSelect}
            closeModal={onCancel}
            initialValues={initialValues}
            submitButton={submitButton}
            onFinish={onFinish}
            title={title}
            formFields={formContent}
            cancelButton={cancelButton}
            formInstance={form}
            requireDeps={requireDeps}
            handleChange={handleChange}
        />
    );
};

export default React.memo(UserModal);

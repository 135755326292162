import {Form as AntForm} from 'antd';
import React from 'react';

import Form from '../../../../components/Form/Form';
import * as formInputNames from '../../../../consts/form/formInputNames';
import {reportTypes} from '../../../../consts/reports/reportTypes.ts';
import buildingsService from '../../../../services/buildings/buildingsService';
import deviceService from '../../../../services/device/deviceService';
import log from '../../../../services/logger/log';
import organizationsService from '../../../../services/organizations/organizationsService';
import {setBuildingsOrganizationId} from '../../../../state/ducks/buildings';
import {dispatch} from '../../../../state/store';
import {utcEndOfDay, utcStartOfDay} from '../../../../utils/dateUtils';
import helpers from '../../../../utils/helpers';
import reportsModalService from './reportsModalService';

const ReportsModal = (props) => {
    const {onOk, onCancel} = props;
    const [form] = AntForm.useForm();

    const modalData = reportsModalService.getModalContent();

    const {formContent, title, submitButton, cancelButton, initialValues, requireDeps} = modalData || {};

    const onFinish = async () => {
        try {
            const values = await form.validateFields();

            helpers.runFunction(onOk, values);
        } catch (error) {
            log.error(`ReportsModal - validate failed: ${error}`);
        }
    };

    const resetFormField = (fieldNames) => {
        const fieldsToReset = {};

        fieldNames.forEach((name) => (fieldsToReset[name] = null));
        form.setFieldsValue(fieldsToReset);
    };

    const setFormField = (name, key, value) => form.setFieldsValue({[name]: {id: key, value}});

    const handleDatePick = (date, name) => {
        if (name === formInputNames.START_DATE) {
            form.setFieldsValue({[name]: utcStartOfDay(date)});
        } else {
            form.setFieldsValue({[name]: utcEndOfDay(date)});
        }
    };

    const handleRadioChange = (value, name) => form.setFieldsValue({[name]: value});

    const handleSelect = async (value, name, option) => {
        const currentValue = form.getFieldValue(name);

        if (currentValue?.value === option.value) return;

        setFormField(name, option.key, option.value);

        switch (name) {
            case formInputNames.REPORT_TYPE: {
                resetFormField([
                    formInputNames.DEVICE_ID,
                    formInputNames.DEVICE_TYPE,
                    formInputNames.END_DATE,
                    formInputNames.START_DATE,
                ]);

                break;
            }
            case formInputNames.CITY: {
                resetFormField([
                    formInputNames.COMPANY,
                    formInputNames.BUILDING,
                    formInputNames.DEVICE_ID,
                    formInputNames.DEVICE_TYPE,
                ]);

                await organizationsService.getOrganizationsNames(value);
                break;
            }
            case formInputNames.COMPANY: {
                resetFormField([formInputNames.BUILDING, formInputNames.DEVICE_ID, formInputNames.DEVICE_TYPE]);

                dispatch(setBuildingsOrganizationId(value));
                await buildingsService.getBuildingsListByOrganizationId();
                break;
            }
            case formInputNames.BUILDING: {
                resetFormField([formInputNames.DEVICE_TYPE, formInputNames.DEVICE_ID]);
                const buildingId = form.getFieldValue(formInputNames.BUILDING);

                await deviceService.getDeviceTypesGroupsByBuildingId(buildingId?.id);
                break;
            }
            case formInputNames.DEVICE_TYPE: {
                resetFormField([formInputNames.DEVICE_ID]);

                const currentReportId = form.getFieldValue(formInputNames.REPORT_TYPE)?.id;
                const isBuildingReport = currentReportId === reportTypes.Building;
                const isIndividualReport = currentReportId === reportTypes.Individual;

                if (isIndividualReport || isBuildingReport) {
                    const buildingId = form.getFieldValue(formInputNames.BUILDING);

                    // await deviceService.getDevices({typeId: option?.key, buildingId: buildingId?.id});
                    await deviceService.getDevices({groupID: option?.key, buildingId: buildingId?.id});
                }

                break;
            }
            default:
                break;
        }
    };

    const handleChange = () => {};

    return (
        <Form
            requireDeps={requireDeps}
            handleDatePick={handleDatePick}
            handleRadioChange={handleRadioChange}
            handleSelect={handleSelect}
            closeModal={onCancel}
            initialValues={initialValues}
            submitButton={submitButton}
            onFinish={onFinish}
            title={title}
            formFields={formContent}
            cancelButton={cancelButton}
            formInstance={form}
            handleChange={handleChange}
        />
    );
};

export default React.memo(ReportsModal);

import {Form as AntForm} from 'antd';
import React, {useEffect} from 'react';

import Form from '../../../../../components/Form/Form';
import * as formInputNames from '../../../../../consts/form/formInputNames';
import log from '../../../../../services/logger/log';
import organizationsService from '../../../../../services/organizations/organizationsService.js';
import {setOrganizationsNames} from '../../../../../state/ducks/organizations';
import {dispatch} from '../../../../../state/store';
import helpers from '../../../../../utils/helpers';
import buildingProfileModalService from './buildingProfileModalService';

const BuildingProfileModal = (props) => {
    const {onCancel, onOk, modalType} = props || {};
    const [form] = AntForm.useForm();

    const {formFields, title, submitButton, cancelButton, initialValues} = buildingProfileModalService.getFormContent(
        modalType,
        form
    );

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
            updateOrganizationsForSelects();
        }

        return () => {
            form.resetFields();
        };
    }, []);

    organizationsService.getOrganizationsNames();

    const resetFormField = (fieldNames) => {
        const fieldsToReset = {};

        fieldNames.forEach((name) => (fieldsToReset[name] = null));
        form.setFieldsValue(fieldsToReset);
    };

    const onFinish = async () => {
        try {
            const values = await form.validateFields();

            helpers.runFunction(onOk, values);
        } catch (error) {
            log.info('Validate Failed:', error);
        }
    };

    const updateOrganizationsForSelects = async () => {
        const organization1 = form.getFieldValue(formInputNames.ORGANIZATION_ID);
        const organization2 = form.getFieldValue(formInputNames.ORGANIZATION_2);
        const organization3 = form.getFieldValue(formInputNames.ORGANIZATION_3);
        const organization4 = form.getFieldValue(formInputNames.ORGANIZATION_4);

        const excludedOrganizations = [organization1, organization2, organization3, organization4];

        const organizations = await organizationsService.getFilteredOrganizations(excludedOrganizations);

        dispatch(setOrganizationsNames(organizations));
    };

    const handleRadioChange = async (value, name) => {
        setFormField(name, value);

        if (name === formInputNames.ADDITIONAL_ORGANIZATIONS && value == 'false') {
            resetFormField([
                formInputNames.ORGANIZATION_2,
                formInputNames.ORGANIZATION_3,
                formInputNames.ORGANIZATION_4,
            ]);
        }
    };

    const setFormField = (name, value) => form.setFieldsValue({[name]: value});

    const handleSelect = async (value, name, option) => {
        const currentValue = form.getFieldValue(name);

        if (currentValue?.value === option.value) return;

        setFormField(name, option.key, option.value);

        if (
            [formInputNames.ORGANIZATION_2, formInputNames.ORGANIZATION_3, formInputNames.ORGANIZATION_4].includes(name)
        ) {
            await updateOrganizationsForSelects();
        }
    };

    const handleChange = () => {};

    return (
        <Form
            handleRadioChange={handleRadioChange}
            handleSelect={handleSelect}
            closeModal={onCancel}
            initialValues={initialValues}
            submitButton={submitButton}
            onFinish={onFinish}
            title={title}
            formFields={formFields}
            cancelButton={cancelButton}
            formInstance={form}
            handleChange={handleChange}
        />
    );
};

export default React.memo(BuildingProfileModal);

import {Form as AntForm} from 'antd';
import React, {useEffect} from 'react';

import Form from '../../../../../components/Form/Form';
import log from '../../../../../services/logger/log';
import helpers from '../../../../../utils/helpers';
import roomsModalService from './roomsModalService';

const RoomsModal = (props) => {
    const {onOk, onCancel, modalType, initialData} = props;
    const [form] = AntForm.useForm();

    const modalData = roomsModalService.getRoomTypeModalContent(modalType, initialData);

    const {formContent, title, submitButton, cancelButton, initialValues} = modalData || {};

    const onFinish = async () => {
        try {
            const values = await form.validateFields();

            values.roomTypeId = initialData?.id;

            helpers.runFunction(onOk, values);
        } catch (error) {
            log.error(`RoomModal - validate failed: ${error}`);
        }
    };

    useEffect(() => {
        return () => {
            form.resetFields();
        };
    }, []);

    const handleChange = () => {};

    return (
        <Form
            closeModal={onCancel}
            submitButton={submitButton}
            onFinish={onFinish}
            title={title}
            formFields={formContent}
            cancelButton={cancelButton}
            formInstance={form}
            initialValues={initialValues}
            handleChange={handleChange}
        />
    );
};

export default React.memo(RoomsModal);

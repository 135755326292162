export const GRID = 'GRID';
export const TABLE = 'TABLE';
export const SETTINGS_COMMON = 'SETTINGS_COMMON';
export const SETTINGS_ROOMS = 'SETTINGS_ROOMS';
export const SETTINGS_DATA_COLLECTORS = 'SETTINGS_DATA_COLLECTORS';
export const SETTINGS_DEVICE_GROUPS = 'SETTINGS_DEVICE_GROUPS';
export const SETTINGS_USERS = 'SETTINGS_USERS';
export const TABLE_ZERO_DEVICES = 'TABLE_ZERO_DEVICES';
export const BUILDING_PROFILE_PAGE_ROOMS_TABLE_TAB = 'BUILDING_PROFILE_PAGE_ROOMS_TABLE_TAB';
export const BUILDING_PROFILE_PAGE_CHART_TAB = 'BUILDING_PROFILE_PAGE_CHART_TAB';
export const BUILDING_PROFILE_PAGE_ROOMS_SECTION_TAB = 'BUILDING_PROFILE_PAGE_ROOMS_SECTION_TAB';
export const COMMON_INDICATORS_TYPE = 'COMMON_INDICATORS_TYPE';
export const EXISTING_INDICATORS_TYPE = 'EXISTING_INDICATORS_TYPE';
export const NEW_INDICATORS_TYPE = 'NEW_INDICATORS_TYPE';
export const MODEMS_TYPE = 'MODEMS_TYPE';
export const USERS_TYPE = 'USERS_TYPE';

export const SETTINGS_ADD_BUILDING = 'SETTINGS_ADD_BUILDING';

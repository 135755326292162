import {Form as AntForm} from 'antd';
import React from 'react';

import Form from '../../../../../components/Form/Form';
import log from '../../../../../services/logger/log';
import helpers from '../../../../../utils/helpers';
import roomToBuildingModalService from './roomToBuildingModalService';

const RoomToBuildingModal = (props) => {
    const [form] = AntForm.useForm();

    const {onOk, onCancel} = props;

    const {formContent, title, submitButton, cancelButton, initialValues} =
        roomToBuildingModalService.getModalContent() || {};

    const onFinish = async () => {
        try {
            const values = await form.validateFields();

            helpers.runFunction(onOk, values);
        } catch (error) {
            log.error(`BuildingRoomModal - validate failed: ${error}`);
        }
    };

    const setFormField = (name, key) => form.setFieldsValue({[name]: key});

    const handleSelect = async (_, name, option) => {
        const currentValue = form.getFieldValue(name);

        if (currentValue?.value === option.value) return;

        setFormField(name, option.key, option.value);
    };

    const handleChange = () => {};

    return (
        <Form
            handleSelect={handleSelect}
            closeModal={onCancel}
            initialValues={initialValues}
            submitButton={submitButton}
            onFinish={onFinish}
            title={title}
            formFields={formContent}
            cancelButton={cancelButton}
            formInstance={form}
            handleChange={handleChange}
        />
    );
};

export default React.memo(RoomToBuildingModal);

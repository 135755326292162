import {Form as AntForm} from 'antd';
import React, {useEffect} from 'react';

import Form from '../../../../../components/Form/Form';
import * as formInputNames from '../../../../../consts/form/formInputNames';
import log from '../../../../../services/logger/log';
import organizationsService from '../../../../../services/organizations/organizationsService';
import helpers from '../../../../../utils/helpers';
import arsBuildingModalService from './arsBuildingsModalService';

const ArsBuildingsModal = (props) => {
    const {onOk, onCancel, selectedBuilding} = props;
    const [form] = AntForm.useForm();

    const modalData = arsBuildingModalService.getAddArsBuildingsModalContent({selectedBuilding});

    const {formContent, title, submitButton, cancelButton, initialValues, requireDeps} = modalData || {};

    const onFinish = async () => {
        try {
            const values = await form.validateFields();

            const formValues = {...initialValues, ...values};

            helpers.runFunction(onOk, formValues);
        } catch (error) {
            log.error(`UserModal - validate failed: ${error}`);
        }
    };

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }

        return () => {
            form.resetFields();
        };
    }, []);

    const resetFormField = (fieldNames) => {
        const fieldsToReset = {};

        fieldNames.forEach((name) => (fieldsToReset[name] = null));
        form.setFieldsValue(fieldsToReset);
    };

    const setFormField = (name, key, value) => form.setFieldsValue({[name]: {id: key, value}});

    const handleRadioChange = (value, name) => {
        form.setFieldsValue({[name]: value});
    };

    const handleSelect = async (value, name, option) => {
        const currentValue = form.getFieldValue(name);

        if (currentValue?.value === option.value) return;

        setFormField(name, option.key, option.value);

        switch (name) {
            case formInputNames.CITY: {
                resetFormField([formInputNames.ORGANIZATION, formInputNames.ALL_BUILDINGS]);

                await organizationsService.getOrganizationsNames(value);
                break;
            }
            case formInputNames.ORGANIZATION: {
                resetFormField([formInputNames.ALL_BUILDINGS]);

                break;
            }
            case formInputNames.REPORT_TYPE: {
                resetFormField([formInputNames.DEVICE_GROUP]);

                break;
            }
            default:
                break;
        }
    };

    const handleChange = () => {};

    return (
        <Form
            handleRadioChange={handleRadioChange}
            handleSelect={handleSelect}
            closeModal={onCancel}
            initialValues={initialValues}
            submitButton={submitButton}
            onFinish={onFinish}
            title={title}
            formFields={formContent}
            cancelButton={cancelButton}
            formInstance={form}
            requireDeps={requireDeps}
            handleChange={handleChange}
        />
    );
};

export default React.memo(ArsBuildingsModal);
